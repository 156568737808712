<template>
  <div class="insuraceFormWrapper">
    <!--    <div id="map" ref="map"></div>-->
    <ValidationObserver ref="camelInfo">
      <form class="insuraceForm">
        <b-row>
          <b-col md="4">
            <p
              class="bg-gray p-2 iq-border-radius-5 text-black text-center font-size-18"
            >
              {{ $t("insurance.chipSerialNumber") }}
            </p>
          </b-col>
          <b-col md="4">
            <p
              class="bg-gray p-2 iq-border-radius-5 text-black text-center font-size-18"
            >
              {{ $t("insurance.camelValue") }}
            </p>
          </b-col>
          <b-col md="3">
            <p
              class="bg-warning-light p-2 iq-border-radius-5 text-black text-center font-size-18"
            >
              {{ $t("insurance.status") }}
            </p>
          </b-col>
        </b-row>
        <b-row
          v-for="(camel, i) in camels"
          :key="i"
          :class="[
            `align-items-top`,
            camel.color === 'error' ? 'errorStatus' : '',
          ]"
        >
          <b-col md="4">
            <input-form
              v-model="camel.chip_number"
              :name="$t('insurance.chipNumber')"
              type="text"
              :validate="'required|numeric|digits:15'"
              :placeholder="$t('insurance.chipNumber')"
            ></input-form>
          </b-col>
          <b-col md="4">
            <main-select
              :options="values"
              v-model="camel.value"
              label="text"
              :reduce="(values) => values.value"
              :placeholder="$t('insurance.camelValue')"
              :validate="'camelRequired'"
              :name="$t('insurance.camelValue')"
              dir="rtl"
            />
          </b-col>
          <b-col md="3">
            <p class="div-color redDiv" v-if="camel.color === 'error'">
              {{ camel.status }}
            </p>
            <p v-else-if="camel.color === 'Done'" class="greenDiv div-color">
              {{ camel.status }}
            </p>
            <p v-else class="div-color bg-white">---</p>
          </b-col>
          <b-col md="1" v-if="camels.length > 1">
            <b-button
              class="py-2 w-100 mt-1 iq-border-radius-5 font-weight-bold px-0"
              @click="removeRow(i)"
              >{{ $t("insurance.delete") }}</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" class="d-flex gap_1">
            <b-button
              variant="warning"
              class="text-white px-4 py-2 border-0 iq-border-radius-5"
              @click="saveCamels"
            >
              {{ $t("insurance.save") }}</b-button
            >
            <b-button
              variant="primary"
              class="iq-border-radius-5 py-2 px-4"
              @click="addNewInsurance"
              v-if="camels.length !== 5"
            >
              {{ $t("insurance.addCamel") }}</b-button
            >
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        class="insuraceForm"
        @submit.prevent="handleSubmit(submitPackageDetails)"
      >
        <b-row>
          <b-col md="4">
            <div class="w-100 my-4">
              <label class="text-black font-size-18"
                >{{ $t("transportation.transportationDate") }}
              </label>
              <flat-picker
                v-model="date"
                class="form-control form-date"
                validate="required"
                :name="$t('transportation.transportationDate')"
                :config="{ minDate: 'today' }"
                :placeholder="$t('transportation.transportationDate')"
              ></flat-picker>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-row>
              <b-col md="6">
                <label class="text-black font-size-18"
                  >{{ $t("transportation.startingPoint") }}
                </label>
                <input-form
                  v-model="startLocation"
                  :name="$t('transportation.startingPoint')"
                  id="startPlace"
                  ref="startLocation"
                  type="text"
                  validate="required"
                  :placeholder="$t('transportation.startingPoint')"
                ></input-form>
              </b-col>
              <b-col md="6">
                <label class="text-black font-size-18">{{
                  $t("transportation.destination")
                }}</label>
                <input-form
                  :name="$t('transportation.destination')"
                  v-model="arrivalLocation"
                  type="text"
                  validate="required"
                  id="endPlace"
                  ref="arrivalLocation"
                  :placeholder="$t('transportation.destination')"
                ></input-form>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <div
              class="div-color w-100 bg-white d-flex justify-content-between px-3 align-items-center"
            >
              <p class="mb-0 font-size-18">
                {{ $t("transportation.tripDistance") }}
              </p>
              <p
                class="mb-0 font-size-18 text-gray font-weight-bold"
                v-if="tripInfo.status === 'OK'"
              >
                {{ tripInfo.distance.text }}
              </p>
            </div>
          </b-col>
          <b-col md="11" class="my-2">
            <hr />
          </b-col>
          <b-col md="11">
            <b-alert show class="alertFees">
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0 font-weight-bold">
                  {{ $t("insurance.totalValueInsured") }}
                </p>
                <p class="mb-0 font-weight-bold">
                  {{ totalFeesPackage }} {{ $t("insurance.rs") }}
                </p>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center my-4 gap_2">
          <b-button
            variant="warning"
            @click="reverse"
            class="px-5 py-2 iq-border-radius-5 text-center text-white font-weight-bold"
          >
            <span class="mx-2"> </span> <i class="las la-angle-right"></i>
            {{ $t("insurance.previous") }}</b-button
          >
          <b-button
            variant="primary"
            type="submit"
            class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold"
          >
            <span class="mx-2"> {{ $t("insurance.continue") }}</span>
            <i class="las la-angle-left"></i>
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
/*eslint-disable */
import insuranceServices from "../../services/insurance";
import { core } from "@/config/pluginInit";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("camelRequired", {
  ...required,
  message: "قيمة المتن is required",
});
export default {
  data() {
    return {
      camels: [
        {
          chip_number: "",
          value: "",
          status: "",
          color: "",
        },
      ],
      date: "",
      startLocation: "",
      arrivalLocation: "",
      map: null,
      start: "",
      end: "",
      startText: "",
      endText: "",
      tripInfo: {},
      values: [
        {
          value: "5000",
          text: "0 - 5000",
        },
        {
          value: "10000",
          text: "5000 - 10000",
        },
        {
          value: "20000",
          text: "10000 - 20000",
        },
        {
          value: "30000",
          text: "20000 - 30000",
        },
        {
          value: "40000",
          text: "30000 - 40000",
        },
        {
          value: "50000",
          text: "40000 - 50000",
        },
        {
          value: "60000",
          text: "50000 - 60000",
        },
        {
          value: "70000",
          text: "60000 - 70000",
        },
        {
          value: "80000",
          text: "70000 - 80000",
        },
        {
          value: "90000",
          text: "80000 - 90000",
        },
        {
          value: "100000",
          text: "90000 - 100000",
        },
      ],
      totalFeesPackage: 0,
    };
  },
  watch: {
    startLocation() {
      this.tripInfo = {};
      const originAutoComplete = new window.google.maps.places.Autocomplete(
        document.getElementById("startPlace"),
        {
          fields: ["formatted_address", "geometry", "name"],
          strictBounds: false,
        }
      );
      originAutoComplete.addListener("place_changed", () => {
        var place = originAutoComplete.getPlace();
        this.startText = place.formatted_address;
        this.startLocation = place.formatted_address;
        this.start = place.geometry.location;
        this.calculateDistance();
      });
    },
    arrivalLocation() {
      this.tripInfo = {};
      const originAutoComplete = new window.google.maps.places.Autocomplete(
        document.getElementById("endPlace"),
        {
          fields: ["formatted_address", "geometry", "name"],
          strictBounds: false,
        }
      );
      originAutoComplete.addListener("place_changed", () => {
        var place = originAutoComplete.getPlace();
        this.endText = place.formatted_address;
        this.arrivalLocation = place.formatted_address;
        this.end = place.geometry.location;
        this.calculateDistance();
      });
    },
  },
  methods: {
    async addNewInsurance() {
      const isValid = await this.$refs.camelInfo.validate();
      if (isValid) {
        const findIndexOfChip = this.camels.findIndex(
          (data) =>
            data.chip_number === this.camels[this.camels.length - 1].chip_number
        );
        if (
          this.camels.length === 0 ||
          findIndexOfChip === this.camels.length - 1
        ) {
          insuranceServices
            .checkSerial(this.camels[this.camels.length - 1].chip_number)
            .then(() => {
              this.camels[this.camels.length - 1].status = this.$t(
                "insurance.chipNumberExists"
              );
              this.camels[this.camels.length - 1].color = "Done";
              var total = 0;
              this.camels.forEach((camel) => {
                total += Number(camel.value);
              });
              this.totalFeesPackage = total;
              this.camels.push({
                chip_number: "",
                value: "",
                status: "",
                color: "",
              });
            })
            .catch(() => {
              this.camels[this.camels.length - 1].status = this.$t(
                "insurance.chipNumberNotExists"
              );
              this.camels[this.camels.length - 1].color = "error";
            });
        } else {
          this.camels[this.camels.length - 1].status = this.$t(
            "insurance.chipNumberDuplicated"
          );
          this.camels[this.camels.length - 1].color = "error";
        }
      }
    },
    async saveCamels() {
      const isValid = await this.$refs.camelInfo.validate();
      if (isValid) {
        const findIndexOfChip = this.camels.findIndex(
          (data) =>
            data.chip_number === this.camels[this.camels.length - 1].chip_number
        );
        if (
          this.camels.length === 0 ||
          findIndexOfChip === this.camels.length - 1
        ) {
          if (this.camels[0].value) {
            insuranceServices
              .checkSerial(this.camels[this.camels.length - 1].chip_number)
              .then(() => {
                this.camels[this.camels.length - 1].status = this.$t(
                  "insurance.chipNumberExists"
                );
                this.camels[this.camels.length - 1].color = "Done";
                var total = 0;
                this.camels.forEach((camel) => {
                  total += Number(camel.value);
                });
                this.totalFeesPackage = total;
              })
              .catch(() => {
                this.camels[this.camels.length - 1].status = this.$t(
                  "insurance.chipNumberNotExists"
                );
                this.camels[this.camels.length - 1].color = "error";
              });
          } else {
            core.showSnackbar("error", "يجب عليك اختيار قيمة المتن");
          }
        } else {
          this.camels[this.camels.length - 1].status = this.$t(
            "insurance.chipNumberDuplicated"
          );
          this.camels[this.camels.length - 1].color = "error";
        }
      }
    },
    removeRow(key) {
      this.camels.splice(key, 1);
      var total = 0;
      this.camels.forEach((camel) => {
        total += Number(camel.value);
      });
      this.totalFeesPackage = total;
    },
    submitPackageDetails() {
      if (this.date) {
        if (this.tripInfo.status === "OK") {
          // eslint-disable-next-line eqeqeq
          if (this.startText == this.endText) {
            core.showSnackbar("error", "جهة الوصول مثل جهة الانطلاق");
          } else {
            const errorData = this.camels.find(
              (data) => data.color === "error"
            );
            if (
              this.camels.length >= 1 &&
              !errorData &&
              this.camels[0].color !== ""
            ) {
              localStorage.setItem("insuranceStep", "fees");
              this.$emit("changeForm", {
                camels: this.camels,
                startText: this.startText,
                endText: this.endText,
                distance: this.tripInfo.distance.value,
                date: this.date,
                totalFeesPackages: this.totalFeesPackage,
              });
            } else {
              core.showSnackbar("error", "يجب التأكد من أرقام الشرائح");
            }
          }
        } else {
          core.showSnackbar(
            "error",
            "يجب التأكد من جهة الوصول أو جهة الإنطلاق"
          );
        }
      } else {
        core.showSnackbar("error", "يجب اختيار تاريخ التأمين");
      }
    },
    reverse() {
      this.$emit("reverse");
    },
    drawMap() {
      // configuration map
      const styleMap = [
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#e9e9e9",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#dedede",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              saturation: 36,
            },
            {
              color: "#333333",
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: "#f2f2f2",
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
      ];
      const location = { lat: 30.064742, lng: 31.249509 };
      const mapZoom = 12;

      // configuration map
      this.map = new window.google.maps.Map(this.$refs.map, {
        zoom: mapZoom,
        center: location,
        styles: styleMap,
      });
    },
    calculateDistance() {
      var service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [this.start],
          destinations: [this.end],
          travelMode: "DRIVING",
        },
        (res) => {
          this.tripInfo = res.rows[0].elements[0];
        }
      );
    },
    checkLocalStorageData() {
      if (
        localStorage.getItem("insuranceInfo") &&
        JSON.parse(localStorage.getItem("insuranceInfo")).camels
      ) {
        this.camels = JSON.parse(localStorage.getItem("insuranceInfo")).camels;
        this.date = JSON.parse(localStorage.getItem("insuranceInfo")).date;
        this.startLocation = JSON.parse(
          localStorage.getItem("insuranceInfo")
        ).startText;
        this.arrivalLocation = JSON.parse(
          localStorage.getItem("insuranceInfo")
        ).endText;
        this.totalFeesPackage = JSON.parse(
          localStorage.getItem("insuranceInfo")
        ).totalFeesPackages;
        this.tripInfo = {
          status: "OK",
          distance: {
            text: JSON.parse(localStorage.getItem("insuranceInfo")).distance,
          },
        };
      }
    },
  },
  mounted() {
    this.drawMap();
  },
  created() {
    this.checkLocalStorageData();
  },
};
</script>
<style>
.insuraceFormWrapper {
  width: 80%;
  margin: auto;
}
#map {
  height: 500px;
}
.save-btn {
  background-color: rgba(211, 157, 69, 0.73) !important;
}
.svae-btn.text-white {
  color: #d39d45 !important;
}
/*.errorStatus {
  border: 1px solid red !important;
  border-radius: 8px;
  color: red !important;
}*/
.greenDiv {
  border: 1px solid #6ebf97 !important;
  color: #6ebf97 !important;
  font-size: 13px !important;
  box-shadow: 0px 0px 9px rgba(110, 191, 151, 0.83);
}
.errorStatus .form-control,
.errorStatus .vs__dropdown-toggle,
.redDiv {
  border: 1px solid red !important;
  color: red !important;
  font-size: 13px !important;
  box-shadow: 0px 0px 9px #ec5d6169;
}
.div-color {
  height: 45px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alertFees {
  background: #3f68541f 0% 0% no-repeat padding-box !important;
  border: 0.800000011920929px solid #7c7c7c93 !important;
  color: var(--iq-primary) !important;
}
</style>
