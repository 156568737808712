<template>
  <div>
    <b-modal
      id="termsAndCondition"
      title="الشروط والأحكام"
      size="lg"
      centered
      hide-footer
    >
      <template #modal-header="{ close }">
        <div
          style="
            display: flex !important;
            justify-content: space-between !important;
            align-items: center !important;
            width: 100% !important;
          "
        >
          <h5 class="modal-title" dir="rtl">الشروط والأحكام</h5>
          <button
            type="button"
            class="close"
            @click="close"
            style="direction: ltr"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </template>

      <p class="text-black font-size-18 text-justify p-4">
        {{ termsAndCondition }}
      </p>
    </b-modal>
    <user-info-and-insurance-company :info="info" :key="currentStep" />
    <div class="fees-company-container">
      <h3 class="fees-company-main-title">
        {{ $t("insurance.ChooseInsuranceCompany") }}
      </h3>
      <b-row>
        <b-col
          lg="6"
          class="mb-4"
          v-for="(item, index) in insuranceCompanyInfos"
          :key="index"
        >
          <div
            :class="[
              'fees-company-item',
              selectedCompany === item ? 'selected' : '',
            ]"
            @click="handleSelect(item)"
          >
            <div
              class="fees-company-item-body d-flex justify-content-between flex-wrap gap_2"
            >
              <!-- img  -->
              <div
                class="fees-company-item-img flex-fill d-flex flex-column justify-content-between gap_1"
              >
                <img
                  :src="
                    item.image ||
                    require('../../../../../assets/images/ibbil/elraghi.png')
                  "
                  alt=""
                  class="img-fluid"
                />
                <div>
                  <h5 class="fees-company-item-title">
                    {{ $t("insurance.amountInsured") }}
                  </h5>
                  <h5 class="fees-company-item-response">
                    {{ info.totalFeesPackages }} {{ $t("insurance.rs") }}
                  </h5>
                </div>
              </div>
              <!-- info  -->
              <div class="fees-company-item-info flex-fill">
                <div class="fees-company-item-info-box">
                  <h5 class="fees-company-item-title">
                    {{ $t("insurance.CertificationFees") }}
                  </h5>
                  <h5 class="fees-company-item-response">
                    {{ item.options.certificate_fees }} {{ $t("insurance.rs") }}
                  </h5>
                </div>
                <div class="fees-company-item-info-box">
                  <h5 class="fees-company-item-title">
                    {{ $t("insurance.transportationInsuranceCoverage") }}
                  </h5>
                  <h5 class="fees-company-item-response">
                    {{ item.transportaion_coverage }} {{ $t("insurance.rs") }}
                  </h5>
                </div>
                <div class="fees-company-item-info-box last">
                  <h5 class="fees-company-item-title">
                    {{ $t("insurance.valueTax") }} {{ item.options.tax }}%
                  </h5>
                  <h5 class="fees-company-item-response">
                    {{
                      (
                        (item.options.tax / 100) *
                        item.transportaion_coverage
                      ).toFixed(2)
                    }}
                    {{ $t("insurance.rs") }}
                  </h5>
                </div>
                <div class="fees-company-item-info-total">
                  <h5 class="fees-company-item-title">
                    {{ $t("insurance.camelTotalValue") }}
                  </h5>
                  <h5 class="fees-company-item-response">
                    {{
                      (item.options.tax / 100) * item.transportaion_coverage +
                      item.transportaion_coverage +
                      item.options.certificate_fees
                    }}
                    <span>{{ $t("insurance.rs") }}</span>
                  </h5>
                </div>
              </div>
            </div>
            <div
              class="fees-company-item-footer gap_1 d-flex align-items-center justify-content-between flex-wrap"
            >
              <p class="fees-company-item-note m-0">
                {{ $t("insurance.enduranceValue") }} {{ item.options.mount }}%
                {{ $t("insurance.insuredLoss") }}
              </p>
              <div class="d-flex align-items-center">
                <a
                  href="#"
                  class="privacy-btn text-nowrap"
                  @click.prevent="showPopupTerms(item.termsAndConditions)"
                  >{{ $t("insurance.termsAndConditions") }}</a
                >
                <button type="button" class="choose">
                  {{ $t("insurance.choose") }}
                </button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="d-flex justify-content-center mb-5 gap_2">
      <b-button
        variant="warning"
        @click="reverse"
        class="px-5 py-2 iq-border-radius-5 text-center text-white font-weight-bold"
      >
        <span class="mx-2"> </span> <i class="las la-angle-right"></i>
        {{ $t("insurance.previous") }}</b-button
      >

      <b-button
        variant="primary"
        @click="changeComponent"
        class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold"
      >
        <span class="mx-2">{{ $t("insurance.continue") }}</span>
        <i class="las la-angle-left"></i>
      </b-button>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import userInfoAndInsuranceCompany from "@/modules/servicePages/insurance/components/userInfoAndInsuranceCompany";
import insuranceServices from "../../services/insurance";
import { core } from "@/config/pluginInit";
export default {
  props: ["info"],
  data() {
    return {
      disabled: false,
      disabledCompany: false,
      insuranceCompanyInfos: [],
      selectedCompany: "",
      termsAndCondition: "",
    };
  },
  components: {
    userInfoAndInsuranceCompany,
  },
  watch: {
    info(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAllCompanies();
      }
    },
  },
  methods: {
    showPopupTerms(termsAndCondition) {
      this.termsAndCondition = termsAndCondition;
      this.$bvModal.show("termsAndConditionPage");
    },
    getAllCompanies() {
      insuranceServices
        .getInsuranceCompanies({
          distance: this.info.distance,
          totalFeesPackages: this.info.totalFeesPackages,
        })
        .then((res) => {
          this.insuranceCompanyInfos = res.data.data;
        });
    },
    handleSelect(company) {
      if (this.selectedCompany === company) {
        this.selectedCompany = null;
      } else {
        this.selectedCompany = company;
      }
    },
    reverse() {
      this.selectedCompany = "";
      this.$emit("reverse");
    },
    changeComponent() {
      if (this.selectedCompany) {
        localStorage.setItem("insuranceStep", "installment");
        this.$emit("changeForm", {
          selectedCompany: this.selectedCompany,
          service_provider_id: this.selectedCompany.id,
        });
      } else {
        core.showSnackbar("error", "يجب اختيار شركة التأمين");
      }
    },
  },
  created() {
    this.getAllCompanies();
  },
};
</script>

<style lang="scss">
.fees-company-container {
  margin-bottom: 52px;
  .fees-company-main-title {
    font-size: 20px !important;
    color: #646464 !important;
    margin-bottom: 32px !important;
    font-weight: bold !important;
  }
  .fees-company-item {
    box-shadow: 0px 3px 20px #0000000d;
    padding: 45px 50px 20px;
    background-color: #fff;
    border-radius: 20px;
    border: 3px solid transparent;
    cursor: pointer;
    transition: 0.3s;
    &.selected {
      border-color: #6ebf97;
    }
    .fees-company-item-body {
      padding-bottom: 27px;
      margin-bottom: 16px;
      border-bottom: 1px solid #dedede;
    }
    .fees-company-item-info-box {
      margin-bottom: 22px;
      &.last {
        padding-bottom: 22px;
        border-bottom: 1px solid #dedede;
      }
    }
    .fees-company-item-title {
      color: #b1b1b1;
      font-size: 16px;
      margin-bottom: 4px;
    }
    .fees-company-item-response {
      color: #9b9b9b;
      font-size: 20px;
      font-weight: bold;
    }
    .fees-company-item-img,
    .fees-company-item-info-total {
      .fees-company-item-title {
        font-size: 20px;
      }
      .fees-company-item-response {
        font-size: 20px;
      }
    }
    .fees-company-item-info-total {
      .fees-company-item-response {
        color: #d39d45;
      }
    }
    .fees-company-item-img {
      img {
        max-width: 150px;
      }
    }
    .fees-company-item-note {
      color: #646464;
      font-size: 16px;
      max-width: 290px;
    }
    .privacy-btn {
      font-size: 16px;
      color: #646464;
      padding-bottom: 3px;
      border-bottom: 1px solid #646464;
      font-weight: bold;
      margin-inline-end: 18px;
    }
    .choose {
      height: 50px;
      border: 1px solid #2b584280;
      border-radius: 10px;
      color: #2b5842;
      font-size: 20px;
      width: 111px;
      background-color: transparent;
    }
  }
}
#termsAndConditionPage .modal-header {
  flex-direction: row-reverse;
}
</style>
